import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kim/Documents/MedGrocer/ep-mg-website/src/components/Layout/Layout.js";
import { graphql, navigate, Link } from "gatsby";
import classNames from "classnames";
import Img from "gatsby-image";
import Hero from "../components/Layout/Hero";
import Container from "../components/Layout/Container";
import HeroBanner from "../components/Elements/HeroBanner";
import MediaBox from "../components/Elements/MediaBox";
import Button from "../components/Elements/Button";
import SEO from "components/Layout/SEO";
import clinics from "../../static/images/icons/icons__analytics--colored.png";
import employeeBenefits from "../../static/images/icons/icons__vaccination--colored.png";
import patientSupport from "../../static/images/icons/icons__pharmacy--colored.png";
import styles from "../../src/components/StaticPages/utils/staticPages.module.scss";
export const query = graphql`
  query {
    corporate: file(
      relativePath: { eq: "corporate/corporate__brain--teal-long.png" }
    ) {
      childImageSharp {
        fluid(
          duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
          toFormat: PNG
          quality: 90
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEO title="Corporate" mdxType="SEO" />
    <Hero background={{
      image: props.data.corporate.childImageSharp.fluid.src,
      position: "center"
    }} color="warning" className={classNames("py-3", styles["careers"])} size="small" mdxType="Hero">
      <Container isCentered desktop={10} fullhd={8} className="mt-2 mb-2" mdxType="Container">
        <div className={styles["banner__body"]}>
          <h1>Delivering healthcare everywhere</h1>
          <p className="mt-2 subtitle is-size-7-tablet is-size-7-mobile">
            <p>{`MedGrocer's technology platforms optimize "medicine-as-a-service" for patients and companies. Its ePharmacy, corporate health services, and patient programs empower customers to get their medicines, vaccines, and medical services conveniently, cost-effectively, and intelligently.`}</p>
          </p>
          <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
            <p>{`Beyond just delivering medicines, we deliver healthcare solutions: from analytics, to customized programs, to end-to-end fulfillment.`}</p>
          </p>
          <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
            <p>{`In partnership with the country's top employers, health insurers, and multinational drug companies, we redefine the medicine experience for thousands of patients everyday.`}</p>
          </p>
          <a href="mailto:solutions@medgrocer.com" target="_blank" rel="noopener noreferrer">
  <Button color="primary" className={styles["banner__button"]} mdxType="Button">
    Contact Us
  </Button>
          </a>
        </div>
      </Container>
    </Hero>
    <Hero mdxType="Hero">
      <Container isCentered desktop={10} fullhd={10} className="mt-5-desktop mt-5-fullhd" mdxType="Container">
        <div className="content has-text-centered pb-2">
          <h1>{`What we can do`}</h1>
        </div>
        <div className="columns is-centered content has-text-centered">
          <MediaBox title="ePharmacy" image={{
            src: patientSupport,
            alt: "Patient Support Programs"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Online Ordering`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Delivery`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Patient Support`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Corporate Healthcare" image={{
            src: employeeBenefits,
            alt: "Corporate Healthcare"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Medicine Benefits`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Testing and Vaccines`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Administration`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Company Clinics" image={{
            src: clinics,
            alt: "Medicines"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Clinic Management`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Medicines and Supplies`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Mental Health EAP (Employee Assistance Program)`}</p>
              </li>
            </ul>
          </MediaBox>
        </div>
      </Container>
    </Hero>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      